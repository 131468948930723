<style scoped>
	.tmp_big{width: 100%;height: 100%;}
	.tpm_box{width: 100%;height: 100%;display: flex;flex-direction: column;}
	.tem_body{flex: 1;width: 100%;}
	.tem_header{padding: 5px;display: flex;align-items: center;};
	.tem_body_contair{height: 100%;width: 100%;}
	.tem_footer{text-align: right;padding: 5px;}
	.checkbox_box{padding: 20px 5px;}
	.save_btn_box{margin-top: 50px;}
</style>

<template>
	<div class="tmp_big">
		<div class="tpm_box">
			<div class="tem_header">
				<el-button type="danger" size="small" @click="thisReload">刷新<i class="el-icon-refresh-right"></i></el-button>
			</div>
			<div class="tem_body" id="tem_body">
				<div class="tem_body_contair" v-loading="pageLoading">
					<div class="checkbox_box">
						<el-row style="margin-bottom: 20px;">
							<el-checkbox size="medium" :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange"
							 border>全选</el-checkbox>
						</el-row>
						<el-checkbox-group v-model="checkboxGroup2" size="medium" @change="handleCheckedChange">
							<el-checkbox v-for="item in checkBoxData" :label="item.service_item_id" style="text-align: center;">
								<div class="a" style="margin-bottom: .3rem;">
									<img style="width: 3rem;" :src="_IMAGE_URL + item.icon" alt="">
								</div>
								{{item.name}}
							</el-checkbox>
						</el-checkbox-group>
						<el-row class="save_btn_box">
							<el-button type="success" @click="api_saveSet">保存设置</el-button>
						</el-row>
					</div>
				</div>
			</div>
			<div class="tem_footer">

			</div>
		</div>
	</div>
</template>

<script>
	var _this;
	export default {
		props: ['upPageParams'],
		data() {
			return {
				pageLoading: true,
				checkBoxData: [],
				checkAll: false,
				isIndeterminate: true,
				checkboxGroup2: [],
				hasChecked: [],
			}
		},
		created: function() {
			_this = this;
			
			_this.api_getBaseData();
			_this.api_getServiceByCommiunity();
		},
		mounted: function() {

		},
		methods: {
			/* -------------
			 * 获取基础列表数据
			-------------*/
			api_getBaseData() {
				_this._getApi('/wy/userServiceItem/getUserItemSelectList', {}).then((res) => {
					if (res.code == 1) {
						var newData = res.data;
						_this.checkBoxData = newData;
					} else {

					}
				}).catch((err) => {});
			},
			/* -------------
			 * 获取该小区拥有的服务
			-------------*/
			api_getServiceByCommiunity() {
				if(!_this.upPageParams.commiunity){
					_this.pageLoading = false;
				}
				_this._getApi('/wy/propertyServiceItemConfig/getResidenceServiceItem', {
					residence_id: _this.upPageParams.commiunity.id
				}).then((res) => {
					if (res.code == 1) {
						var newData = res.data;
						if (newData.service_item_ids) {
							var ids = newData.service_item_ids;
							_this.checkboxGroup2 = ids.split(',');
							_this.checkboxGroup2 = _this.checkboxGroup2.map(Number);
						}
						_this.pageLoading = false;
					} else {
						_this.pageLoading = false;
					}
				}).catch((err) => {});
			},
			/* -------------
			 * 全选
			-------------*/
			handleCheckAllChange(val) {
				var allIds = [];
				_this.checkBoxData.forEach(function(item) {
					allIds.push(item.service_item_id)
				});
				_this.checkboxGroup2 = val ? allIds : [];
				_this.isIndeterminate = false;
			},
			/* -------------
			 * 选择框改变监听
			-------------*/
			handleCheckedChange(value) {
				var checkedCount = value.length;
				_this.checkAll = checkedCount === _this.checkBoxData.length;
				_this.isIndeterminate = checkedCount > 0 && checkedCount < _this.checkBoxData.length;
			},
			/* -------------
			 * 设置
			-------------*/
			api_saveSet() {
				var service_item_ids = [];
				if(_this.checkboxGroup2.length>0){
					service_item_ids = _this.checkboxGroup2;
				}
				_this._postApi('/wy/propertyServiceItemConfig/setResidenceServiceItem', {
					service_item_ids: service_item_ids,
					residence_id: _this.upPageParams.commiunity.id
				}).then((res) => {
					if (res.code == 1) {
						_this.$message({
							message: '设置成功',
							type: 'success',
							duration: 1000,
							onClose: function() {
								_this.thisReload();
							}
						});
					} else {
						_this.$confirm(res.msg, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {

						}).catch(() => {

						});
					}
				}).catch((err) => {});
			},
			/* -------------
			 * 刷新
			-------------*/
			thisReload() {
				_this.pageLoading = true;
				_this.api_getBaseData();
				_this.api_getServiceByCommiunity();
			}
		}
	}
</script>
